import React from "react";
import AutoScroll from "embla-carousel-auto-scroll";
import useEmblaCarousel from "embla-carousel-react";

import "./slider.scss";

const Slider = ({ slides, direction }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { axis: "y", loop: true, watchDrag: false },
    [
      AutoScroll({
        direction,
        speed: 1,
        stopOnInteraction: false,
        stopOnMouseEnter: false,
        stopOnFocusIn: false,
        startDelay: 0,
      }),
    ]
  );

  return (
    <section className="slider">
      <div className="embla__viewport slider-viewport" ref={emblaRef}>
        <div className="embla__container slider-container">
          {slides.map((slide) => (
            <div className="embla__slide slider-slide" key={slide.id}>
              <div
                className="slider-content"
                onMouseOver={() => emblaApi?.plugins().autoScroll.stop()}
                onMouseOut={() => emblaApi?.plugins().autoScroll.play()}
              >
                <p
                  className="slider-review"
                  dangerouslySetInnerHTML={{ __html: slide.review }}
                ></p>
                <div className="slider-user">
                  <img
                    src={slide.picture}
                    alt={slide.name}
                    className="slider-picture"
                  />
                  <div className="slider-user-info">
                    <p className="slider-name">{slide.name}</p>
                    <p className="slider-title">{slide.title}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Slider;
