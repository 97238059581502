import React, { Component } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  ConversationList,
  Conversation,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import io from "socket.io-client";

import DashSidebar from "../../../components/dashboard/sidebar";
import Spinner from "../../../components/spinner";

import "./queries.scss";

import ChatLogo from "../../../assets/chat_logo.png";
import notificationSound from "../../../assets/notification.mp3";

let socket;
let getDatePart = (dateString) => dateString.split("T")[0];

export class ChatQueries extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem("token")),
      chatData: [],
      chat: [],
      group: [],
      socketConnected: false,
      disabled: false,
      queryType: localStorage.getItem("queryType") || 0,
    };

    if (this.state.user.package === "Free")
      return (window.location.href = "/packages");

    document.title = "Dashboard - Queries";

    this.setQueryType = this.setQueryType.bind(this);
  }

  async componentDidMount() {
    socket = io(process.env.REACT_APP_BACKEND_URL);
    socket.emit("setup", this.state.user.id);
    socket.on("connection", () => this.setState({ socketConnected: true }));

    socket.on("message received", (message) => {
      const messageDate = getDatePart(message.createdAt);

      const chatDataCopy = [...this.state.chatData];
      const dateIndex = chatDataCopy.findIndex(
        (group) => group.date === messageDate
      );

      if (dateIndex !== -1) chatDataCopy[dateIndex].messages.push(message);
      else
        chatDataCopy.unshift({
          date: messageDate,
          messages: [message],
          disabled: false,
        });

      this.setState({ chatData: chatDataCopy });

      let audio = new Audio(notificationSound);
      audio.volume = 0.4;
      audio.play();
    });

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/chat/get?type=query`,
        config
      );

      if (data.success) {
        console.log(data);
        const groupedMessages = data.message.reduce((acc, message) => {
          const date = getDatePart(message.createdAt);

          if (!acc[date]) {
            acc[date] = [];
          }

          acc[date].push(message);

          return acc;
        }, {});

        const today = new Date();
        const formattedDate = `${today.getFullYear()}-${String(
          today.getMonth() + 1
        ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

        const sortedGroupedMessages = Object.keys(groupedMessages)
          .sort()
          .reverse()
          .map((date) => ({
            date,
            messages: groupedMessages[date],
            disabled: formattedDate === date ? false : true,
          }));

        this.setState({
          loading: false,
          chatData: sortedGroupedMessages,
          group: sortedGroupedMessages,
        });
      }

      socket.emit("join chat", data.message[0].chatID);
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  componentWillUnmount() {
    if (socket) {
      socket.off("message received");
    }
  }

  async sendMessage(text) {
    if (!text || text === "") return;

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/chat/send?type=query`,
        { content: text },
        config
      );

      if (data.success) {
        const messageDate = getDatePart(data.data.createdAt);

        const chatDataCopy = [...this.state.chatData];
        const dateIndex = chatDataCopy.findIndex(
          (group) => group.date === messageDate
        );

        if (dateIndex !== -1) chatDataCopy[dateIndex].messages.push(data.data);
        else
          chatDataCopy.unshift({
            date: messageDate,
            messages: [data.data],
            disabled: false,
          });

        this.setState({ chatData: chatDataCopy });
        socket.emit("new message", data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  setQueryType(event) {
    event.preventDefault();
    if (this.state.queryType === 0)
      return alert("Please select a query type first");
    else {
      localStorage.setItem("queryType", this.state.queryType);
      alert("Query type has been set to " + this.state.queryType);
    }
  }

  render() {
    return (
      <div className="dashboard">
        <div className="sidebar">
          <DashSidebar></DashSidebar>
        </div>
        <div className="contents">
          <h1>Queries</h1>

          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (
            <div className="d-flex flex-row my-3">
              <div className="w-25">
                <ConversationList>
                  <Conversation
                    info="LegalX Query Team"
                    name="All Queries"
                    onClick={() => this.setState({ chat: [], disabled: false })}
                  >
                    <Avatar name="LegalX Query Team" src={ChatLogo} />
                  </Conversation>
                  {this.state.group.map((g, i) => (
                    <Conversation
                      info="LegalX Query Team"
                      name={new Date(g.date).toDateString()}
                      onClick={() =>
                        this.setState({
                          chat: g.messages,
                          disabled: g.disabled,
                        })
                      }
                    >
                      <Avatar name="LegalX Query Team" src={ChatLogo} />
                    </Conversation>
                  ))}
                </ConversationList>
              </div>
              <div className="inbox p-4 pb-0 flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
                  <h4>LegalX Query Team</h4>
                  <form
                    className="d-flex align-items-center gap-2"
                    onSubmit={this.setQueryType}
                  >
                    <select
                      className="form-control"
                      value={this.state.queryType}
                      onChange={(event) =>
                        this.setState({ queryType: event.target.value })
                      }
                    >
                      <option value={0}>Select Query Type</option>
                      <option value={"General Query"}>General Query</option>
                      <option value={"Rape/Harrasment/Abuse Query"}>
                        Rape/Harrasment/Abuse Query
                      </option>
                    </select>
                    <button className="button" type="submit">
                      Submit
                    </button>
                  </form>
                </div>
                <div className="chat">
                  <MainContainer>
                    <ChatContainer
                      style={{
                        borderRadius: "5px",
                        height: "678px",
                        padding: "20px 0",
                      }}
                    >
                      <MessageList>
                        {this.state.chat.length <= 0
                          ? this.state.chatData.map((c, i) => (
                              <>
                                <MessageSeparator
                                  key={i + 1}
                                  content={new Date(c.date).toDateString()}
                                />
                                {c.messages.map((message, index) => (
                                  <Message
                                    model={{
                                      message: message.content,
                                      direction:
                                        this.state.user.id === message.sender
                                          ? "outgoing"
                                          : "incoming",
                                    }}
                                    key={index}
                                  >
                                    <Avatar
                                      src={
                                        this.state.user.id === message.sender
                                          ? this.state.user.avatar
                                          : ChatLogo
                                      }
                                    ></Avatar>
                                  </Message>
                                ))}
                              </>
                            ))
                          : this.state.chat.map((message, index) => (
                              <Message
                                model={{
                                  message: message.content,
                                  direction:
                                    this.state.user.id === message.sender
                                      ? "outgoing"
                                      : "incoming",
                                }}
                                key={index}
                              >
                                <Avatar
                                  src={
                                    this.state.user.id === message.sender
                                      ? this.state.user.avatar
                                      : ChatLogo
                                  }
                                ></Avatar>
                              </Message>
                            ))}
                      </MessageList>
                      <MessageInput
                        disabled={this.state.disabled}
                        placeholder={
                          this.state.disabled
                            ? "Previous queries"
                            : "Type your message here"
                        }
                        onSend={(text) => this.sendMessage(text)}
                      />
                    </ChatContainer>
                  </MainContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ChatQueries;
