import React, { Component } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faCalendarCheck,
  faCircleCheck,
  faClock,
  faLocationArrow,
  faTag,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { faClockFour } from "@fortawesome/free-regular-svg-icons";
import { Markup } from "interweave";
import { DatePicker, Rate } from "antd";
import dayjs from "dayjs";

import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

import Spinner from "../../components/spinner";

import { withRouter } from "../../controllers/withRouter";

import "./lawyer.scss";

export class Lawyer extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      lawyer: {},
      infoActive: "overview",
      user: jwtDecode(localStorage.getItem("token")),
      date: "",
      slots: [],
    };

    this.updateSlot = this.updateSlot.bind(this);
  }

  async componentDidMount() {
    if (this.state.user.package === "Free")
      return (window.location.href = "/packages");

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/lawyer/data/${this.props.params.id}`,
        config
      );

      if (data.success) {
        this.setState({ lawyer: data.lawyer, loading: false, date: "" }, () =>
          this.updateSlot(this.state.date)
        );
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  updateSlot(dateString) {
    let date = new Date(dateString).getDay();

    if (date === 0)
      return this.setState({
        slots: this.state.lawyer.appointments.slots.sunday.availableSlots,
        time: this.state.lawyer.appointments.slots.sunday.availableSlots[0],
      });
    if (date === 1)
      return this.setState({
        slots: this.state.lawyer.appointments.slots.monday.availableSlots,
        time: this.state.lawyer.appointments.slots.sunday.availableSlots[0],
      });
    if (date === 2)
      return this.setState({
        slots: this.state.lawyer.appointments.slots.tuesday.availableSlots,
        time: this.state.lawyer.appointments.slots.sunday.availableSlots[0],
      });
    if (date === 3)
      return this.setState({
        slots: this.state.lawyer.appointments.slots.wednesday.availableSlots,
        time: this.state.lawyer.appointments.slots.sunday.availableSlots[0],
      });
    if (date === 4)
      return this.setState({
        slots: this.state.lawyer.appointments.slots.thursday.availableSlots,
        time: this.state.lawyer.appointments.slots.sunday.availableSlots[0],
      });
    if (date === 5)
      return this.setState({
        slots: this.state.lawyer.appointments.slots.friday.availableSlots,
        time: this.state.lawyer.appointments.slots.sunday.availableSlots[0],
      });
    if (date === 6)
      return this.setState({
        slots: this.state.lawyer.appointments.slots.saturday.availableSlots,
        time: this.state.lawyer.appointments.slots.sunday.availableSlots[0],
      });
  }

  submit() {
    if (this.state.date === "")
      return alert("Please choose a date for appointment");
    this.setState({ loading: true });

    localStorage.setItem(
      "booking",
      JSON.stringify({
        name: this.state.lawyer.name,
        address: this.state.lawyer.location.address,
        picture: this.state.lawyer.picture,
        date: this.state.date,
        time: this.state.time,
        verified: this.state.lawyer.verified,
        lawyerID: this.state.lawyer._id,
      })
    );

    window.location.href = "/lawyer/booking";
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        {this.state.loading ? (
          <Spinner></Spinner>
        ) : (
          <div className="lawyer">
            <div className="container py-5">
              <div className="row">
                <div className="col-md-9">
                  <div className="lawyer-info">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="d-flex align-items-center gap-3">
                          <img
                            src={this.state.lawyer.picture}
                            alt={this.state.lawyer.name}
                            className="img-fluid rounded"
                            width="150px"
                          />
                          <div>
                            <h5 className="d-flex align-items-center justify-content-center my-2">
                              {this.state.lawyer.name}
                              <span className="verified ms-2">
                                {this.state.lawyer.verified ? (
                                  <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    size="2xs"
                                  ></FontAwesomeIcon>
                                ) : null}
                              </span>
                            </h5>
                            <p className="category">
                              {this.state.lawyer.category.map((cat, idnex) => (
                                <div>{cat.label}</div>
                              ))}
                            </p>
                            <Rate value={5}></Rate>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 lawyer-details">
                        <div>
                          <div className="d-flex align-items-center gap-3 mb-3">
                            <FontAwesomeIcon
                              icon={faLocationArrow}
                              size="lg"
                              className="color-"
                            />
                            <div className="details">
                              {this.state.lawyer.location.region}
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3 mb-3">
                            <FontAwesomeIcon icon={faClock} size="lg" />
                            <div className="details">
                              <span
                                className={
                                  new Date().getDay() === 1 ? "bold" : ""
                                }
                              >
                                Mon
                              </span>
                              ,
                              <span
                                className={
                                  new Date().getDay() === 2 ? "bold" : ""
                                }
                              >
                                Tue
                              </span>
                              ,
                              <span
                                className={
                                  new Date().getDay() === 3 ? "bold" : ""
                                }
                              >
                                Wed
                              </span>
                              ,
                              <span
                                className={
                                  new Date().getDay() === 4 ? "bold" : ""
                                }
                              >
                                Thu
                              </span>
                              ,
                              <span
                                className={
                                  new Date().getDay() === 5 ? "bold" : ""
                                }
                              >
                                Fri
                              </span>
                              ,
                              <span
                                className={
                                  new Date().getDay() === 6 ? "bold" : ""
                                }
                              >
                                Sat
                              </span>
                              ,
                              <span
                                className={
                                  new Date().getDay() === 0 ? "bold" : ""
                                }
                              >
                                Sun
                              </span>
                              ,
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3 mb-3">
                            <FontAwesomeIcon icon={faTag} size="lg" />
                            <div className="details">
                              Starting from{" "}
                              <span className="bold">
                                &#2547; {this.state.lawyer.pricing[0]}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3 mb-3">
                            <FontAwesomeIcon icon={faCalendar} size="lg" />
                            <div className="details available">
                              Available Today
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="lawyer-overview">
                    <ul className="nav">
                      <li>
                        <div
                          className={
                            this.state.infoActive === "overview" ? "active" : ""
                          }
                          onClick={() =>
                            this.setState({ infoActive: "overview" })
                          }
                        >
                          Overview
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            this.state.infoActive === "gallery" ? "active" : ""
                          }
                          onClick={() =>
                            this.setState({
                              infoActive: "gallery",
                            })
                          }
                        >
                          Gallery
                        </div>
                      </li>
                      <li>
                        <div
                          className={
                            this.state.infoActive === "review" ? "active" : ""
                          }
                          onClick={() =>
                            this.setState({
                              infoActive: "review",
                            })
                          }
                        >
                          Review
                        </div>
                      </li>
                    </ul>

                    {this.state.infoActive === "overview" && (
                      <div>
                        <h4>About "{this.state.lawyer.name}"</h4>
                        <p>{this.state.lawyer.description}</p>

                        <h4>Education</h4>

                        <ul className="legal-list">
                          {this.state.lawyer.education?.map((edu, index) => (
                            <li className="tabs" key={index}>
                              <ul className="legal-list">
                                <li>
                                  <div className="user">
                                    <div className="before-circle"></div>
                                  </div>
                                  <div className="content">
                                    <div className="timeline-content">
                                      <h6>{edu.name}</h6>
                                      <p>{edu.designation}</p>
                                      <div className="time">
                                        {new Date(edu.start).toDateString()} -{" "}
                                        {new Date(edu.end).toDateString()}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          ))}
                        </ul>

                        <h4>Experience</h4>

                        <ul className="legal-list">
                          {this.state.lawyer.experience?.map((edu, index) => (
                            <li className="tabs" key={index}>
                              <div className="legal-list">
                                <li>
                                  <div className="user">
                                    <div className="before-circle"></div>
                                  </div>
                                  <div className="content">
                                    <div className="timeline-content">
                                      <h6>{edu.name}</h6>
                                      <p>{edu.designation}</p>
                                      <div className="time">
                                        {new Date(edu.start).toDateString()} -{" "}
                                        {new Date(edu.end).toDateString()}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </div>
                            </li>
                          ))}
                        </ul>

                        <h4>Certificates</h4>

                        <ul className="legal-list">
                          {this.state.lawyer.certification?.map(
                            (edu, index) => (
                              <li className="tabs" key={index}>
                                <div className="legal-list">
                                  <li>
                                    <div className="user">
                                      <div className="before-circle"></div>
                                    </div>
                                    <div className="content">
                                      <div className="timeline-content">
                                        <h6>{edu.name}</h6>
                                        <p>{edu.for}</p>
                                        <div className="time">
                                          {new Date(edu.at).toDateString()}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}

                    {this.state.infoActive === "gallery" && (
                      <img
                        className="img-fluid rounded"
                        src={this.state.lawyer.picture}
                        width="250px"
                      />
                    )}
                    {this.state.infoActive === "review" && (
                      <section id="feedback">
                        <h5>Private Feedback</h5>
                        <div className="stars">
                          <div className="d-flex align-items-center gap-5">
                            <div>
                              <p>Service</p>
                              <Rate></Rate>
                            </div>
                            <div>
                              <p>Value for money</p>
                              <Rate></Rate>
                            </div>
                          </div>
                          <div className="comment mt-5">
                            <p className="mb-2">Comment *</p>
                            <textarea
                              className="form-control"
                              cols="30"
                              rows="10"
                            ></textarea>
                          </div>
                          <button className="button mt-3">Post comment</button>
                        </div>
                      </section>
                    )}
                  </div>

                  {/* <div className="row">
                    <div className="col-md-4 lawyer-info">
                      <img
                        src={this.state.lawyer.picture}
                        alt={this.state.lawyer.name}
                        className="img-fluid rounded-circle"
                        width="150px"
                      />
                      <h3 className="d-flex align-items-center justify-content-center">
                        {this.state.lawyer.name}
                        <span className="verified ms-2">
                          {this.state.lawyer.verified ? (
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size="2xs"
                            ></FontAwesomeIcon>
                          ) : null}
                        </span>
                      </h3>
                      <div className="about">
                        {this.state.lawyer.description}
                      </div>

                      <h5 className="text-start mb-3">Categories: </h5>
                      <div className="category">
                        {this.state.lawyer.category.map((cat, idnex) => (
                          <div>{cat.label}</div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-8 px-4">
                      <div className="credentials">
                        <ul className="nav">
                          <li>
                            <div
                              className={
                                this.state.infoActive === "education"
                                  ? "active"
                                  : ""
                              }
                              onClick={() =>
                                this.setState({ infoActive: "education" })
                              }
                            >
                              Education
                            </div>
                          </li>
                          <li>
                            <div
                              className={
                                this.state.infoActive === "experience"
                                  ? "active"
                                  : ""
                              }
                              onClick={() =>
                                this.setState({
                                  infoActive: "experience",
                                })
                              }
                            >
                              Experience
                            </div>
                          </li>
                          <li>
                            <div
                              className={
                                this.state.infoActive === "certificate"
                                  ? "active"
                                  : ""
                              }
                              onClick={() =>
                                this.setState({
                                  infoActive: "certificate",
                                })
                              }
                            >
                              Certificate
                            </div>
                          </li>
                        </ul>
                        <div>
                          {this.state.infoActive === "education" ? (
                            <div>
                              {this.state.lawyer.education?.map((edu, i) => (
                                <div key={i} className="creds">
                                  <div className="name">{edu.name}</div>
                                  <div className="desig">{edu.designation}</div>
                                  <div className="time">
                                    {new Date(edu.start).toDateString()} -{" "}
                                    {new Date(edu.end).toDateString()}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : this.state.infoActive === "experience" ? (
                            <div>
                              {this.state.lawyer.experience?.map((exp, i) => (
                                <div key={i} className="creds">
                                  <div className="name">{exp.name}</div>
                                  <div className="desig">{exp.designation}</div>
                                  <div className="time">
                                    {new Date(exp.start).toDateString()} -{" "}
                                    {new Date(exp.end).toDateString()}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              {this.state.lawyer.certification?.map(
                                (cert, i) => (
                                  <div key={i} className="creds">
                                    <div className="name">{cert.name}</div>
                                    <div className="desig">{cert.for}</div>
                                    <div className="time">
                                      {new Date(cert.at).toDateString()}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="d-flex align-items-center">
                    <img src={this.state.lawyer.picture} alt="" className="img-fluid rounded-circle" width='100px' />
                    <div className="lawyer-info ms-4">
                      <h3 className='d-flex align-items-center'>{this.state.lawyer.name}<span className='verified ms-2'>{this.state.lawyer.verified ? <FontAwesomeIcon icon={faCircleCheck} size='2xs'></FontAwesomeIcon> : null}</span></h3>
                      <p className='d-flex align-items-center'><FontAwesomeIcon icon={faLocationDot} color='#888' className='me-2'></FontAwesomeIcon> {this.state.lawyer.location.address}</p>
                    </div>
                  </div>

                  <ul className='nav'>
                    <li><a href='#overview' className={this.state.infoActive === 'overview' ? 'active' : ''}>Overview</a></li>
                    <li><a href="#feedback" className={this.state.infoActive === 'feedback' ? 'active' : ''}>Private Feedback</a></li>
                  </ul>

                  <section id="overview">
                    <div className="mt-3">
                      <Markup content={this.state.lawyer.description}></Markup>

                      <div className="overview-info">
                        <div className="contact"><a href={'tel:' + this.state.lawyer.phoneNumber}><FontAwesomeIcon icon={faPhone} size='1x' className='me-2'></FontAwesomeIcon> {this.state.lawyer.phoneNumber}</a></div>
                        <div className="contact"><a href={'mailto:' +  this.state.lawyer.email}><FontAwesomeIcon icon={faEnvelope} size='1x' className='me-2'></FontAwesomeIcon> {this.state.lawyer.email}</a></div>
                      </div>
                    </div>
                  </section> */}
                </div>
                <div className="col-md-3">
                  <div className="mt-4 mb-2 opening">
                    <h5>
                      <FontAwesomeIcon
                        icon={faCalendarCheck}
                        size="1x"
                        className="me-2"
                      ></FontAwesomeIcon>{" "}
                      Booking
                    </h5>
                    <form className="booking-form">
                      <DatePicker
                        disabledDate={(current) =>
                          current && current < dayjs().endOf("day")
                        }
                        required={true}
                        onChange={(date, dateString) =>
                          this.setState({ date: dateString }, () =>
                            this.updateSlot(dateString)
                          )
                        }
                        maxTagCount="response"
                        size="large"
                        className="mb-2"
                        style={{ width: "100%" }}
                      ></DatePicker>

                      {this.state.date === "" ? (
                        <div className="danger">
                          <p>Please select a date to see available slots</p>
                        </div>
                      ) : this.state.slots.length === 0 ? (
                        <div className="danger">
                          <p>
                            Unfortunately we are closed at selected hours. Try
                            different please
                          </p>
                        </div>
                      ) : (
                        <select
                          onChange={(event) =>
                            this.setState({ time: event.target.value })
                          }
                          className="form-control mb-3"
                        >
                          {this.state.slots.map((slot, index) => (
                            <option value={slot} key={index}>
                              {slot}
                            </option>
                          ))}
                        </select>
                      )}

                      <div className="d-grid">
                        <button
                          className="button"
                          type="submit"
                          onClick={() => this.submit()}
                        >
                          Book Now
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="mt-4 mb-2 opening">
                    <h5>
                      <FontAwesomeIcon
                        icon={faClockFour}
                        size="1x"
                        className="me-2"
                      ></FontAwesomeIcon>{" "}
                      Opening Hours
                    </h5>
                    <ul>
                      <li>
                        Monday{" "}
                        {this.state.lawyer.appointments.slots.monday
                          .startTime === "" ||
                        this.state.lawyer.appointments.slots.monday
                          .startTime === "00:00" ? (
                          <span>Closed</span>
                        ) : (
                          <span>
                            {
                              this.state.lawyer.appointments.slots.monday
                                .startTime
                            }{" "}
                            -{" "}
                            {
                              this.state.lawyer.appointments.slots.monday
                                .endTime
                            }
                          </span>
                        )}
                      </li>
                      <li>
                        Tuesday{" "}
                        {this.state.lawyer.appointments.slots.tuesday
                          .startTime === "" ||
                        this.state.lawyer.appointments.slots.tuesday
                          .startTime === "00:00" ? (
                          <span>Closed</span>
                        ) : (
                          <span>
                            {
                              this.state.lawyer.appointments.slots.tuesday
                                .startTime
                            }{" "}
                            -{" "}
                            {
                              this.state.lawyer.appointments.slots.tuesday
                                .endTime
                            }
                          </span>
                        )}
                      </li>
                      <li>
                        Wednesday{" "}
                        {this.state.lawyer.appointments.slots.wednesday
                          .startTime === "" ||
                        this.state.lawyer.appointments.slots.wednesday
                          .startTime === "00:00" ? (
                          <span>Closed</span>
                        ) : (
                          <span>
                            {
                              this.state.lawyer.appointments.slots.wednesday
                                .startTime
                            }{" "}
                            -{" "}
                            {
                              this.state.lawyer.appointments.slots.wednesday
                                .endTime
                            }
                          </span>
                        )}
                      </li>
                      <li>
                        Thursday{" "}
                        {this.state.lawyer.appointments.slots.thursday
                          .startTime === "" ||
                        this.state.lawyer.appointments.slots.thursday
                          .startTime === "00:00" ? (
                          <span>Closed</span>
                        ) : (
                          <span>
                            {
                              this.state.lawyer.appointments.slots.thursday
                                .startTime
                            }{" "}
                            -{" "}
                            {
                              this.state.lawyer.appointments.slots.thursday
                                .endTime
                            }
                          </span>
                        )}
                      </li>
                      <li>
                        Friday{" "}
                        {this.state.lawyer.appointments.slots.friday
                          .startTime === "" ||
                        this.state.lawyer.appointments.slots.friday
                          .startTime === "00:00" ? (
                          <span>Closed</span>
                        ) : (
                          <span>
                            {
                              this.state.lawyer.appointments.slots.friday
                                .startTime
                            }{" "}
                            -{" "}
                            {
                              this.state.lawyer.appointments.slots.friday
                                .endTime
                            }
                          </span>
                        )}
                      </li>
                      <li>
                        Saturday{" "}
                        {this.state.lawyer.appointments.slots.saturday
                          .startTime === "" ||
                        this.state.lawyer.appointments.slots.saturday
                          .startTime === "00:00" ? (
                          <span>Closed</span>
                        ) : (
                          <span>
                            {
                              this.state.lawyer.appointments.slots.saturday
                                .startTime
                            }{" "}
                            -{" "}
                            {
                              this.state.lawyer.appointments.slots.saturday
                                .endTime
                            }
                          </span>
                        )}
                      </li>
                      <li>
                        Sunday{" "}
                        {this.state.lawyer.appointments.slots.sunday
                          .startTime === "" ||
                        this.state.lawyer.appointments.slots.sunday
                          .startTime === "00:00" ? (
                          <span>Closed</span>
                        ) : (
                          <span>
                            {
                              this.state.lawyer.appointments.slots.sunday
                                .startTime
                            }{" "}
                            -{" "}
                            {
                              this.state.lawyer.appointments.slots.sunday
                                .endTime
                            }
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer></Footer>
      </div>
    );
  }
}

export default withRouter(Lawyer);
