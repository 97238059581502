import React, { Component } from "react";
import { jwtDecode } from "jwt-decode";
import Spinner from "../../../components/spinner";
import axios from "axios";

import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/footer";

import "./appointment.scss";

export class Appointment extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem("token")),
      lawyers: [],
    };
  }

  async componentDidMount() {
    if (this.state.user.package === "Free")
      return (window.location.href = "/packages");

    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/lawyers`
      );

      if (data.success) this.setState({ lawyers: data.lawyers });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="appointment">
          <div className="container">
            <h1>Book an appointment with us</h1>

            <h5 className="text-center">
              Book an appointment with us from our hand picked and best in the
              field lawyers
            </h5>

            {this.state.loading ? (
              <Spinner></Spinner>
            ) : (
              <>
                <div className="row my-5">
                  {this.state.lawyers.map((lawyer, index) => (
                    <>
                      <div
                        className="col-md-4 d-flex align-items-center flex-column gap-4 mb-5"
                        key={index}
                      >
                        <img
                          src={lawyer.picture}
                          className="img-fluid w-50 rounded-circle"
                          alt={lawyer.name}
                        />
                        <div className="text-center">
                          <h2>{lawyer.name}</h2>
                          <p>{lawyer.category[0].label}</p>
                          <button
                            className="button-outline"
                            onClick={() =>
                              (window.location.href = `/dashboard/book-appointment/schedule/${lawyer._id}`)
                            }
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Appointment;
