import One from "../../assets/testimonials/1.webp";
import Two from "../../assets/testimonials/2.webp";
import Three from "../../assets/testimonials/3.webp";

export const testimonials = [
  {
    id: 1,
    review:
      "I was having some personal problems and had no idea who or where to turn for help until I came across LegalX. They assisted me with every aspect of my legal issues, and their prices are reasonable.",
    name: "Dayaan Ameen",
    title: "",
    picture: One,
  },
  {
    id: 2,
    review:
      "LegalX was ideal for me because I wanted to handle legal tasks without visiting a lawyer's office. The representatives I spoke with were quite knowledgeable, and their service is fantastic. I would strongly recommend LegalX.",
    name: "Ziaul Hassan",
    title: "Managing Director, SSL Network",
    picture: Two,
  },
  {
    id: 3,
    review:
      "I initially got in touch with LegalX because I needed a lawyer and wanted to see if they might assist me. Since I was in legal difficulties, I urgently required assistance. I connected with a lawyer through Legalx based on my needs. I was quite pleased with LegalX's services.",
    name: "Sabira Mehrin Saba",
    title: "CEO, Wander Woman",
    picture: Three,
  },
  {
    id: 4,
    review:
      "I was having some personal problems and had no idea who or where to turn for help until I came across LegalX. They assisted me with every aspect of my legal issues, and their prices are reasonable.",
    name: "Dayaan Ameen",
    title: "",
    picture: One,
  },
  {
    id: 5,
    review:
      "LegalX was ideal for me because I wanted to handle legal tasks without visiting a lawyer's office. The representatives I spoke with were quite knowledgeable, and their service is fantastic. I would strongly recommend LegalX.",
    name: "Ziaul Hassan",
    title: "Managing Director, SSL Network",
    picture: Two,
  },
  {
    id: 6,
    review:
      "I initially got in touch with LegalX because I needed a lawyer and wanted to see if they might assist me. Since I was in legal difficulties, I urgently required assistance. I connected with a lawyer through Legalx based on my needs. I was quite pleased with LegalX's services.",
    name: "Sabira Mehrin Saba",
    title: "CEO, Wander Woman",
    picture: Three,
  },
  {
    id: 7,
    review:
      "I was having some personal problems and had no idea who or where to turn for help until I came across LegalX. They assisted me with every aspect of my legal issues, and their prices are reasonable.",
    name: "Dayaan Ameen",
    title: "",
    picture: One,
  },
  {
    id: 8,
    review:
      "LegalX was ideal for me because I wanted to handle legal tasks without visiting a lawyer's office. The representatives I spoke with were quite knowledgeable, and their service is fantastic. I would strongly recommend LegalX.",
    name: "Ziaul Hassan",
    title: "Managing Director, SSL Network",
    picture: Two,
  },
  {
    id: 9,
    review:
      "I initially got in touch with LegalX because I needed a lawyer and wanted to see if they might assist me. Since I was in legal difficulties, I urgently required assistance. I connected with a lawyer through Legalx based on my needs. I was quite pleased with LegalX's services.",
    name: "Sabira Mehrin Saba",
    title: "CEO, Wander Woman",
    picture: Three,
  },
  {
    id: 10,
    review:
      "I was having some personal problems and had no idea who or where to turn for help until I came across LegalX. They assisted me with every aspect of my legal issues, and their prices are reasonable.",
    name: "Dayaan Ameen",
    title: "",
    picture: One,
  },
  {
    id: 11,
    review:
      "LegalX was ideal for me because I wanted to handle legal tasks without visiting a lawyer's office. The representatives I spoke with were quite knowledgeable, and their service is fantastic. I would strongly recommend LegalX.",
    name: "Ziaul Hassan",
    title: "Managing Director, SSL Network",
    picture: Two,
  },
  {
    id: 12,
    review:
      "I initially got in touch with LegalX because I needed a lawyer and wanted to see if they might assist me. Since I was in legal difficulties, I urgently required assistance. I connected with a lawyer through Legalx based on my needs. I was quite pleased with LegalX's services.",
    name: "Sabira Mehrin Saba",
    title: "CEO, Wander Woman",
    picture: Three,
  },
];
