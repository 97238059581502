import React, { Component } from "react";
import axios from "axios";
import dateFormat from "dateformat";

import DashSidebar from "../../components/dashboard/sidebar";
import Spinner from "../../components/spinner";

import "./accounts.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export class Account extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      user: "",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      toggleCurrentPassword: "password",
      toggleNewPassword: "password",
      toggleConfirmNewPassword: "password",
      profilePicture: "",
      orders: "",
    };

    this.togglePassword = this.togglePassword.bind(this);

    document.title = "My Account - LegalX";
  }

  togglePassword(type) {
    console.log("run", type);
    if (type === "currentPassword") {
      if (this.state.toggleCurrentPassword === "password")
        return this.setState({ toggleCurrentPassword: "text" });
      this.setState({ toggleCurrentPassword: "password" });
    }
    if (type === "newPassword") {
      if (this.state.toggleNewPassword === "password")
        return this.setState({ toggleNewPassword: "text" });
      this.setState({ toggleNewPassword: "password" });
    }
    if (type === "confirmNewPassword") {
      if (this.state.toggleConfirmNewPassword === "password")
        return this.setState({ toggleConfirmNewPassword: "text" });
      this.setState({ toggleConfirmNewPassword: "password" });
    }
  }

  async submitProfilePicture() {
    this.setState({ loading: true });

    const formData = new FormData();

    formData.append("profilePicture", this.state.profilePicture);

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/profile-picture`,
        formData,
        config
      );

      if (data.success) {
        alert("Profile picture have been updated");
        localStorage.setItem("token", data.token);
        window.location.href = "/dashboard/my-account";
      }
    } catch (error) {
      console.error(error);
    }
  }

  async componentDidMount() {
    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/user`,
        config
      );

      if (data.success)
        this.setState({ loading: false, user: data.data, orders: data.orders });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="dashboard">
        <div className="sidebar">
          <DashSidebar></DashSidebar>
        </div>
        <div className="contents">
          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (
            <div>
              <h1>My Account</h1>

              <div className="form p-4">
                <h3>Profile Picture</h3>
                <br />
                <div>
                  <img
                    src={this.state.user.avatar}
                    alt="avatar"
                    className="img-fluid avatar mb-4"
                  />
                  <input
                    type="file"
                    className="form-control"
                    onChange={(event) =>
                      this.setState({ profilePicture: event.target.files[0] })
                    }
                  />
                </div>

                <button
                  className="button mt-3"
                  onClick={() => this.submitProfilePicture()}
                >
                  Submit
                </button>
              </div>

              <div className="form p-4">
                <h3 className="mb-2">My Orders</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Time</th>
                      <th>Type</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orders.map((order, i) => (
                      <tr key={i}>
                        <td>{order.orderID}</td>
                        <td>{dateFormat(order.createdAt, "fullDate")}</td>
                        <td>
                          {order.package !== "instant" &&
                          order.package !== "schedule"
                            ? `Package (${order.package})`
                            : `Appointment (${order.package})`}
                        </td>
                        <td>
                          &#2547;
                          {order.price}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="form p-4">
                <h3>Change Password</h3>
                <div className="mb-2">
                  <label>Current Password</label>
                  <div className="d-flex align-items-center">
                    <input
                      type={this.state.toggleCurrentPassword}
                      className="form-control"
                      required
                      value={this.state.currentPassword}
                      onChange={(event) =>
                        this.setState({ currentPassword: event.target.value })
                      }
                    />
                    <span
                      onClick={() => this.togglePassword("currentPassword")}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        size="1x"
                        className="eyes"
                      ></FontAwesomeIcon>
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <label>New Password</label>
                  <div className="d-flex align-items-center">
                    <input
                      type={this.state.toggleNewPassword}
                      className="form-control"
                      required
                      value={this.state.newPassword}
                      onChange={(event) =>
                        this.setState({ newPassword: event.target.value })
                      }
                    />
                    <span onClick={() => this.togglePassword("newPassword")}>
                      <FontAwesomeIcon
                        icon={faEye}
                        size="1x"
                        className="eyes"
                      ></FontAwesomeIcon>
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <label>Confirm New Password</label>
                  <div className="d-flex align-items-center">
                    <input
                      type={this.state.toggleConfirmNewPassword}
                      className="form-control"
                      required
                      value={this.state.confirmNewPassword}
                      onChange={(event) =>
                        this.setState({
                          confirmNewPassword: event.target.value,
                        })
                      }
                    />
                    <span
                      onClick={() => this.togglePassword("confirmNewPassword")}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        size="1x"
                        className="eyes"
                      ></FontAwesomeIcon>
                    </span>
                  </div>

                  <button className="button my-3" type="submit">
                    Submit
                  </button>
                </div>
              </div>

              <div className="form p-4">
                <h3>Your Profile</h3>
                <div className="mb-2">
                  <div className="row">
                    <div className="col-md-6">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.firstName}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.user.lastName}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={this.state.user.email}
                    disabled={true}
                  />
                </div>

                <div className="mb-2">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={this.state.user.phoneNumber}
                    onChange={(event) =>
                      this.setState({ phoneNumber: event.target.value })
                    }
                  />
                </div>

                <div className="mb-2">
                  <label>Package Type</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.user.package}
                    disabled={true}
                  />
                </div>

                <button className="button mt-3" type="submit">
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Account;
